import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TwoColDisplay from "../../components/two-col-display"

const DashPage = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "profile-dash" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  // const node = query.allContentfulArtistImage.edges[0].node
  // const image = node.image.file.url
  // const work = node.work?.map(item => item.file.url)
  // const gatsbyImage = node.image.gatsbyImage
  const node = query.allFile.edges[0].node
  const image = node.childImageSharp.gatsbyImageData
  // console.log(gatsbyImage)

  return (
    <Layout>
      <Seo title="Dash" />
      <div className="grid grid-cols-1 items-center justify-center w-full">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-4xl">
            <TwoColDisplay image={image}>
              <div className="text-center">
                <a href="https://instagram.com/dashytatts">
                  <h2 className="font-rubik text-xl no-underline text-black">
                    @dashytatts
                  </h2>
                </a>
                <div className="px-8 grid grid-cols-1 gap-4 py-4">
                  <div>I am a mystery</div>
                  <div>From parts unknown</div>
                  <div>My name is Dash (real name)</div>
                  <div>
                    I’d love to tell you all about me but it’s better that some
                    things remain a secret (legal reasons) Everything I do is
                    done with my heart and soul , so I hope you can see the
                    passion in my art
                  </div>
                  <div>With love,</div>
                  <div>-</div>
                </div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
        <hr></hr>
        {/* <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-4 max-w-2xl m-auto">
          {work.map(url => (
            <div>
              <img className="w-64 h-64" src={url}></img>
            </div>
          ))}
        </div> */}
      </div>
    </Layout>
  )
}

export default DashPage

// page query to get the artist data
// export const query = graphql`
//   query {
//     allContentfulArtistImage(filter: { name: { eq: "dash" } }) {
//       edges {
//         node {
//           id
//           spaceId
//           image {
//             file {`
